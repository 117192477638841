import React, { useMemo, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { TextArea } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
import { PolicyModel, updatePolicy } from '@app/api/policy.api';

interface UpdatePolicyModalProps {
  policy: keyof PolicyModel;
  data: PolicyModel;
  open: boolean;
  onCancel: () => void;
  onFinish: () => void;
}

const initialValues: PolicyModel = {
  id: -1,
  about: '',
  lang: 'en',
  terms: '',
  privacyPolicy: '',
};

export const UpdatePolicyModal: React.FC<UpdatePolicyModalProps> = ({ open, onCancel, onFinish, data, policy }) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  useResetFormOnCloseModal({
    form,
    open,
  });

  const onOk = async () => {
    form.submit();
  };

  const handleSubmit = async (values: PolicyModel) => {
    setLoading(true);

    updatePolicy(data.id, { [policy]: values[policy] })
      .then(() => {
        setLoading(false);
        notificationController.success({
          message: 'Update category',
          description: 'Successfully',
        });
        onFinish();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const formValues = useMemo(
    () =>
      data
        ? {
            id: data.id,
            lang: data.lang,
            about: data.about,
            terms: data.terms,
            privacyPolicy: data.privacyPolicy,
          }
        : initialValues,
    [data],
  );

  const getLabel = () => {
    switch (policy) {
      case 'about':
        return 'About';
      case 'terms':
        return 'Terms';
      case 'privacyPolicy':
        return 'Privacy Policy';
      default:
        return '';
    }
  };

  return (
    <Modal title="Update Policies" open={open} onOk={onOk} onCancel={onCancel} confirmLoading={isLoading}>
      <BaseForm
        initialValues={formValues}
        form={form}
        layout="vertical"
        name="updatePolicyForm"
        onFinish={handleSubmit}
      >
        <BaseForm.Item
          name={policy}
          label={getLabel()}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <TextArea rows={20} />
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};
