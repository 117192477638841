import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import UserManagerPage from '@app/pages/UserManagerPage';
import VideoManagerPage from '@app/pages/VideoManagerPage';
import CustomerManagerPage from '@app/pages/CustomerManagerPage';
import DashboardPage from '@app/pages/DashboardPage';
import CategoryPage from '@app/pages/CategoryPage';
import CommentManagerPage from '@app/pages/CommentManagerPage';
import CommentLv2ManagerPage from '@app/pages/CommentLv2ManagerPage';
import CouponPage from '@app/pages/CouponPage';
import ModelPage from '@app/pages/ModelPage';
import PolicyPage from '@app/pages/PolicyPage';

const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
// const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
// const NotificationsPage = React.lazy(() => import('@app/pages/NotificationsPage'));
const Logout = React.lazy(() => import('./Logout'));

export const DASHBOARD_PATH = '/';

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
// const SecuritySettings = withLoading(SecuritySettingsPage);
// const Notifications = withLoading(NotificationsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={DASHBOARD_PATH} element={protectedLayout}>
          {/* <Route index element={<DashboardPage />} /> */}
          <Route index element={<DashboardPage />} />
          <Route element={<VideoManagerPage />} />
          <Route path="user/list" element={<UserManagerPage />} />
          <Route path="video/list" element={<VideoManagerPage />} />
          <Route path="comment/list" element={<CommentManagerPage />} />
          <Route path="commentlv2/:commentId" element={<CommentLv2ManagerPage />} />
          <Route path="customer/list" element={<CustomerManagerPage />} />
          <Route path="category" element={<CategoryPage />} />
          <Route path="coupon" element={<CouponPage />} />
          <Route path="model" element={<ModelPage />} />
          <Route path="policies" element={<PolicyPage />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
          </Route>
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
