import { httpApi } from '@app/api/http.api';

export interface PolicyModel {
  id: number;
  lang: string;
  about: string;
  terms: string;
  privacyPolicy: string;
}

export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
  defaultPageSize?: number;
  showSizeChanger?: boolean;
  pageSizeOptions?: string[] | number[];
}

export interface PolicyListRequest {
  page?: number;
  limit?: number;
}

export interface PolicyListResponse {
  data: PolicyModel[];
  hasNextPage: boolean;
  pagination: Pagination;
}

export interface PolicyTableRow {
  id: number;
  lang: string;
  about: string;
  terms: string;
  privacyPolicy: string;
}

export interface UpdatePolicyData {
  lang?: string;
  about?: string;
  terms?: string;
  privacyPolicy?: string;
}

export const getPolicyList = async (payload: PolicyListRequest) => {
  return httpApi.get('/policies', { params: payload });
};

export const updatePolicy = (id: number, data: UpdatePolicyData): Promise<undefined> =>
  httpApi.patch<undefined>(`/policies/${id}`, { ...data }).then(({ data }) => data);
