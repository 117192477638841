import React, { useEffect, useState, useCallback } from 'react';
import { Space } from 'antd';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useMounted } from '@app/hooks/useMounted';
import { Card } from '@app/components/common/Card/Card';
import { CategoryTableRow, Pagination } from '@app/api/category.api';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { getLanguageByCode } from '@app/utils/utils';
import { getPolicyList, PolicyModel, PolicyTableRow } from '@app/api/policy.api';
import { UpdatePolicyModal } from '@app/components/policy/UpdatePolicyModal';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
  total: 0,
  defaultPageSize: 10,
  showSizeChanger: true,
  pageSizeOptions: ['10', '50', '100'],
};

const PolicyPage: React.FC = () => {
  const [tableData, setTableData] = useState<{
    data: CategoryTableRow[];
    pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { isMounted } = useMounted();
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<PolicyModel | null>(null);
  const [selectedKey, setSelectedKey] = useState<keyof PolicyModel | null>(null);

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getPolicyList({
        page: pagination.current,
        limit: pagination.pageSize,
      }).then((res) => {
        setTableData({
          data: res.data.data,
          pagination: { ...pagination, total: res.data.total },
          loading: false,
        });
      });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleEdit = (policy: keyof PolicyModel, record: PolicyTableRow) => {
    setItemSelected(record);
    setSelectedKey(policy);
    setTimeout(() => {
      setIsEditOpen(true);
    }, 250);
  };

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const columns: ColumnsType<PolicyTableRow> = [
    {
      title: 'Language',
      dataIndex: 'lang',
      key: 'lang',
      render: (_, record) => getLanguageByCode(record.lang)?.name,
    },
    {
      title: 'About',
      key: 'about',
      render: (_, record) => (
        <Space>
          <Button type="link" onClick={() => handleEdit('about', record)}>
            Edit
          </Button>
        </Space>
      ),
    },
    {
      title: 'Terms',
      key: 'terms',
      render: (_, record) => (
        <Space>
          <Button type="link" onClick={() => handleEdit('terms', record)}>
            Edit
          </Button>
        </Space>
      ),
    },
    {
      title: 'Privacy Policy',
      key: 'privacyPolicy',
      render: (_, record) => (
        <Space>
          <Button type="link" onClick={() => handleEdit('privacyPolicy', record)}>
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Card padding="0 1.875rem" title="Policies">
        <Table
          loading={tableData.loading}
          columns={columns}
          dataSource={tableData.data}
          pagination={{ ...tableData.pagination, style: { alignItems: 'center' } }}
          onChange={handleTableChange}
          scroll={{ x: 800 }}
          bordered
          rowKey={(record) => record.id}
        />
      </Card>
      <BaseButtonsForm.Provider
        onFormFinish={(name) => {
          if (name === 'updateCategoryForm') {
            setIsEditOpen(false);
          }
        }}
      >
        {itemSelected && selectedKey && (
          <UpdatePolicyModal
            open={isEditOpen}
            onCancel={() => setItemSelected(null)}
            onFinish={() =>
              setTimeout(() => {
                setItemSelected(null);
                fetch(tableData.pagination);
              }, 250)
            }
            data={itemSelected}
            policy={selectedKey}
          />
        )}
      </BaseButtonsForm.Provider>
    </>
  );
};

export default PolicyPage;
